<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <form-wizard
          :subtitle="null"
          :title="null"
          color="rgba(var(--vs-primary), 1)"
          errorColor="rgba(var(--vs-danger), 1)"
          @on-complete="$router.push('/a/users')"
        >
          <tab-content :before-change="validateStep1" class="mb-5" icon="feather icon-upload" title="Upload Users">
            <!-- tab 1 content -->
            <vx-card class="d-theme-dark-light-bg mt-1">
              <div class="vx-row items-center rounded">
                <div class="vx-col md:w-1/2 w-full p-10">
                  <h1 class="my-3">Upload Users</h1>
                  <p>
                    Use this template to fill in the details to mass upload users to Intellectual Point Learn.<br />Use Microsoft Excel or
                    Google Sheets to edit the template
                  </p>
                  <vs-button
                    class="mt-4 font-bold"
                    color="primary"
                    href="https://cdn.discordapp.com/attachments/1198019095665311887/1200898496014852106/Learn_Upload_Users_Template.xlsx?ex=65c7dae1&is=65b565e1&hm=645012f9598781cc79e75e4b98b7485cfe13d9aa2eec09d7731f96722bee8631&"
                    icon="icon-download"
                    icon-pack="feather"
                    type="border"
                    >Download Template
                  </vs-button>
                </div>
                <div class="vx-col md:w-1/2 w-full p-10">
                  <upload-file dropTitle="Drop Users File or" allowedFileTypes=".xlsx" :onSuccess="this.readerData"></upload-file>
                  <h6 v-if="excelData.success === false && excelData.errorMsg" class="mt-2 text-danger font-semibold">
                    {{ excelData.errorMsg }}
                  </h6>
                  <h6 v-if="excelData.success === true && excelData.results" class="mt-2 text-success font-semibold">
                    Uploaded File Contains {{ excelData.results.length }} Valid Users
                  </h6>
                </div>
              </div>
            </vx-card>
          </tab-content>
          <tab-content :before-change="validateStep2" class="mb-5" icon="feather icon-edit" title="Edit Users">
            <vs-table
              :data="excelData.results"
              v-if="excelData.results && excelData.results"
              max-items="10"
              pagination
              search
              :key="excelTableKey"
            >
              <template slot="header">
                <h1 class="ml-4">Uploaded Users</h1>
              </template>

              <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Email</vs-th>
                <vs-th>Password</vs-th>
                <vs-th>Group</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach['email']">
                  <vs-td :data="`${attach['First Name']} ${attach['Last Name']}`">
                    {{ `${attach['First Name']} ${attach['Last Name']}` }}
                  </vs-td>

                  <vs-td :data="`${attach['Email']}`">
                    {{ `${attach['Email']}` }}
                  </vs-td>

                  <vs-td :data="attach['Password']">
                    {{ attach['Password'] }}
                  </vs-td>

                  <vs-td :data="attach['Group']">
                    {{ attach['Group'] ? attach['Group'] : 'None' }}
                  </vs-td>

                  <vs-td>
                    <vs-button
                      color="primary"
                      size="small"
                      type="border"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="editUserStart(attach, index)"
                    >
                      Edit
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep3" class="mb-5" icon="feather icon-grid" title="Assign Apps">
            <vs-table :data="appSearchResults" :sst="true" max-items="5" search @search="appSearch" class="mb-2">
              <template slot="header">
                <h3 class="ml-4">Search Apps</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="attach in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.icon" class="rounded mr-3" width="40px" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="assignApp(attach)"> Assign </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-divider />
            <vs-table :data="appAssignSelected" max-items="5" pagination class="mt-2" :key="appAssignTableKey">
              <template slot="header">
                <h3 class="ml-4 mt-4 mb-3">Assigned Apps</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.icon" class="rounded mr-3" width="40px" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="appAssignSelected.splice(index, 1)"> Remove </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep4" class="mb-5" icon="feather icon-book" title="Assign Courses">
            <vs-table :data="courseSearchResults" :sst="true" max-items="5" search @search="courseSearch" class="mb-2">
              <template slot="header">
                <h3 class="ml-4">Search Courses</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="attach in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.thumbnail" class="rounded mr-3" width="40px" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="assignCourse(attach)"> Assign </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-divider />
            <vs-table :data="courseAssignSelected" max-items="5" pagination class="mt-2" :key="courseAssignTableKey">
              <template slot="header">
                <h3 class="ml-4 mt-4 mb-3">Assigned Courses</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Start</vs-th>
                <vs-th>Expiry</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.thumbnail" class="rounded mr-3" width="40px" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td :data="attach.starting_date" v-if="attach.starting_date">
                    {{
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }).format(attach.starting_date)
                    }}
                  </vs-td>

                  <vs-td v-else> Immediately </vs-td>

                  <vs-td :data="attach.endingDate" v-if="attach.endingDate">
                    {{
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }).format(attach.endingDate)
                    }}
                  </vs-td>

                  <vs-td :data="attach.calculate_end_date" v-if="attach.calculate_end_date">
                    {{ attach.calculate_end_date }}
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="courseAssignSelected.splice(index, 1)">
                      Remove
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep5" class="mb-5" icon="feather icon-eye" title="Review Details">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <h1>{{ goodToSubmit ? 'Ready To Create Users, Press Next To Create' : 'Fix Errors Before Re-Submitting' }}</h1>
                <h3 class="mt-2">Total Users : {{ excelData.results.length }}</h3>
                <h3 class="mt-2">Total Courses : {{ courseAssignSelected.length }}</h3>
                <h3 class="mt-2">Total Apps : {{ appAssignSelected.length }}</h3>
                <h3 class="mt-2" v-if="goodToSubmitErrors.length !== 0">Total Errors : {{ goodToSubmitErrors.length }}</h3>
                <vs-button class="mt-4" @click="validateStep4" v-if="!goodToSubmit">Re-Check For Errors</vs-button>
              </div>
              <div class="vx-col w-1/2 p-12" style="background-color: #161719" v-if="goodToSubmitErrors.length !== 0">
                <div style="max-height: 650px; overflow-y: scroll">
                  <vue-json-pretty :data="goodToSubmitErrors" />
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content class="mb-5" icon="feather icon-check" title="Complete">
            <div class="vx-col w-full mt-3 mb-2">
              <h1>User Upload Count : {{ excelData.results.length }}</h1>
            </div>
            <div v-for="item in submittedSuccess" :key="item.course.id" class="mb-2">
              <p>-----------------------------------------------------</p>
              <h2>Course Name: {{ item.course.name }}</h2>
              <h2>New Subs Created: {{ item.newSubs }}</h2>
              <h2>Existing Subs Updated: {{ item.existingSubs }}</h2>
              <p>-----------------------------------------------------</p>
            </div>
          </tab-content>
        </form-wizard>
      </vx-card>
    </div>
    <vs-popup :active.sync="editSingleUserActive" title="Edit User Values">
      <div class="popup-input">
        <vs-input v-model="editSingleUser.firstName" class="mb-8 w-full" label-placeholder="First Name" />
        <vs-input v-model="editSingleUser.lastName" class="mb-8 w-full" label-placeholder="Last Name" />
        <vs-input v-model="editSingleUser.email" class="mb-8 w-full" v-validate="'required|min:3'" label-placeholder="Email" />
        <vs-input v-model="editSingleUser.password" class="mb-6 w-full" label-placeholder="Password" />
        <vs-button color="success" type="filled" @click="editUserSave">Save Changes</vs-button>
      </div>
    </vs-popup>
    <vs-popup :active.sync="courseAssignPopup" :title="`Assign Course`">
      <vx-card> </vx-card>
      <div class="mt-6 mb-4">
        <h6>Choose Subscription Expiry</h6>
        <datepicker
          :inline="true"
          placeholder="Select Date"
          v-model="courseAssignCalendar"
          class="datepicker-popup-inline mt-4"
        ></datepicker>
        <ul class="flex mt-5">
          <li>
            <vs-radio v-model="courseAssignDuration" @input="calculateCalendarDate" vs-value="1" class="mr-4">1 Month</vs-radio>
          </li>
          <li>
            <vs-radio v-model="courseAssignDuration" @input="calculateCalendarDate" vs-value="3" class="mr-4">3 Months</vs-radio>
          </li>
          <li>
            <vs-radio v-model="courseAssignDuration" @input="calculateCalendarDate" vs-value="6" class="mr-4">6 Months</vs-radio>
          </li>
          <li>
            <vs-radio v-model="courseAssignDuration" @input="calculateCalendarDate" vs-value="12" class="mr-4">1 Year</vs-radio>
          </li>
        </ul>
      </div>

      <p class="text-danger font-bold" v-if="courseAssignCalendar === null">You Must Choose A Subscription Duration</p>

      <vs-button color="primary" type="filled" @click="assignCourseConfirm" v-if="courseAssignCalendar">Assign Course </vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import UploadFile from '@/components/upload/UploadFile';
import * as XLSX from 'xlsx';
import Datepicker from 'vuejs-datepicker';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  data() {
    return {
      excelTableKey: 0,
      excelData: {
        success: false,
        errorMsg: '',
        header: null,
        results: [],
        meta: null,
      },

      editSingleUserActive: false,
      editSingleUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        index: null,
      },

      appSearchResults: [],
      appAssignTableKey: 0,
      appAssignSelected: [],

      courseSearchResults: [],
      courseAssignTableKey: 0,
      courseAssignSelected: [],

      groupSearchResults: [],
      groupAssignTableKey: 0,
      groupAssignSelected: [],

      courseAssignPopup: false,
      courseAssignData: {},
      courseAssignDuration: null,

      courseAssignCalendar: null,
      subscriptionsCreate: [],
      appsCreate: [],

      goodToSubmit: true,
      goodToSubmitErrors: [],

      submittedUsers: false,
      submittedWarnings: [],
      submittedErrors: [],
      submittedSuccess: [],
    };
  },
  methods: {
    appSearch(term) {
      this.$http.get(`platform/app?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.appSearchResults = response.data.rows;
        }
      });
    },
    assignCourseConfirm() {
      const pl = this.courseAssignData;

      pl.endingDate = this.courseAssignCalendar.getTime();

      this.courseAssignSelected.push(pl);
      this.courseAssignData = {};
      this.courseAssignPopup = false;
      this.courseAssignDuration = null;
      this.courseAssignCalendar = null;
    },
    assignCourse(course) {
      let addCourse = true;
      this.courseAssignSelected.forEach((item) => {
        if (item.id === course.id) {
          addCourse = false;
          return false;
        }
      });

      if (addCourse === true) {
        this.courseAssignData = course;
        this.courseAssignPopup = true;
      } else {
        this.$vs.notify({
          title: 'Course Already Assigned',
          text: 'The courses you tried to assign has already been assigned',
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle',
        });
      }
    },
    assignApp(app) {
      let addApp = true;
      this.appAssignSelected.forEach((item) => {
        if (item.id === app.id) {
          addApp = false;
          return false;
        }
      });

      if (addApp === true) {
        this.appAssignSelected.push(app);
      } else {
        this.$vs.notify({
          title: 'App Already Assigned',
          text: 'The app you tried to assign has already been assigned',
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle',
        });
      }
    },
    calculateCalendarDate() {
      const date = new Date();
      const months = parseInt(this.courseAssignDuration);
      date.setMonth(date.getMonth() + months);
      this.courseAssignCalendar = date;
    },
    courseSearch(term) {
      this.$http.get(`course?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.courseSearchResults = response.data.rows;
        }
      });
    },
    editUserSave() {
      this.excelData.results.splice(this.editSingleUser.index, 1);

      this.excelData.results.unshift({
        'First Name': this.editSingleUser.firstName,
        'Last Name': this.editSingleUser.lastName,
        Email: this.editSingleUser.email.toLowerCase(),
        Password: this.editSingleUser.password,
      });

      this.editSingleUserActive = false;
      this.excelTableKey++;
    },
    editUserStart(user, index) {
      this.editSingleUser = {
        firstName: user['First Name'],
        lastName: user['Last Name'],
        email: user['Email'],
        password: user['Password'],
        index,
      };
      this.editSingleUserActive = true;
    },
    excelError(msg) {
      this.excelData.success = false;
      this.excelData.errorMsg = msg;

      this.$vs.notify({
        title: 'Sheet Parsing Error',
        text: msg,
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
    excelSuccess(msg) {
      this.excelData.success = true;

      this.$vs.notify({
        title: 'File Uploaded Successfully',
        text: msg,
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    fixUsers() {
      this.excelData.results.forEach((item) => {
        item['First Name'] = item['First Name'].charAt(0).toUpperCase() + item['First Name'].slice(1).toLowerCase();
        item['Last Name'] = item['Last Name'].charAt(0).toUpperCase() + item['Last Name'].slice(1).toLowerCase();
        item['Email'] = item['Email'].toLowerCase();
        item['Password'] = 'student12345';
      });
      this.$vs.notify({
        title: 'Syntax Automatically Fixed',
        text: 'Uploaded users have been formatted and prefilled with passwords',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C = undefined;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    generateData({ header, results, meta }) {
      if (!header.includes('First Name')) {
        return this.excelError('Missing "First Name" Column, Please Use The Template File.');
      }

      if (!header.includes('Last Name')) {
        return this.excelError('Missing "Last Name" Column, Please Use The Template File.');
      }

      if (!header.includes('Email')) {
        return this.excelError('Missing "Email" Column, Please Use The Template File.');
      }

      if (results.length <= 0) {
        return this.excelError('File Is Empty, Please Fill Out Values Before Uploading.');
      }

      this.excelSuccess('File Uploaded & Parsed Successfully');
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        if (this.excelData.success === true) {
          this.fixUsers();
          resolve(true);
        } else {
          this.$vs.notify({
            title: 'No File Uploaded',
            text: 'You must upload a file before continuing',
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle',
          });
          reject('correct all values');
        }
      });
    },
    validateStep2() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    validateStep3() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    validateStep4() {
      return new Promise((resolve) => {
        this.appsCreate = [];
        this.appAssignSelected.forEach((item) => {
          this.appsCreate.push(item.id);
        });

        this.subscriptionsCreate = [];
        this.courseAssignSelected.forEach((item) => {
          const subscription = {
            courseId: item.id,
            endingDate: item.endingDate,
          };
          if (item.starting_date) {
            subscription.starting_date = item.starting_date;
          }
          this.subscriptionsCreate.push(subscription);
        });

        resolve(true);
      });
    },
    validateStep5() {
      return new Promise((resolve, reject) => {
        if (this.goodToSubmit !== true) {
          this.$vs.notify({
            title: 'Outstanding Errors',
            text: 'Please resolve all errors before submitting',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        if (this.submittedUsers !== false) {
          this.$vs.notify({
            title: 'Already Submitted Users',
            text: 'Please refresh the page to start again.',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        const pl = {
          users: this.excelData.results,
          subscriptions: this.subscriptionsCreate,
          apps: this.appsCreate,
        };

        this.$vs.loading();

        this.$http
          .post('user/mass', pl)
          .then((response) => {
            if (response.status === 200) {
              this.$vs.loading.close();

              this.submittedSuccess = response.data;
            }
          })
          .catch((exception) => {
            this.$vs.loading.close();
            this.goodToSubmit = false;

            this.goodToSubmitErrors.push({
              code: 'FAILURE',
              error: exception.response.data.error.description,
            });

            return this.$vs.notify({
              title: 'Errors With Submission',
              text: 'No Users Have Been Created',
              color: 'danger',
              position: 'top-right',
            });
          });

        resolve(true);
      });
    },
  },
  mounted() {
    this.appSearch('');
    this.courseSearch('');
    this.groupSearch('');
  },
  components: {
    UploadFile,
    FormWizard,
    TabContent,
    XLSX,
    Datepicker,
    VueJsonPretty,
  },
};
</script>
<style lang="scss"></style>
